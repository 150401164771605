<template>
    <el-scrollbar class="storeGoodsAdd">
        <div class="storeGoodsAdd-container">
        <div class="storeGoodsAdd-title">
            <span>填写商品信息</span>
        </div>
        <div class="storeGoodsAdd-middle">
            <div class="storeGoodsAdd-middle-span">
                <span></span>
                <span>商品基本信息</span>
            </div>
            <div class="storeGoodsAdd-form">
                <el-form ref="form" :model="form" label-width="100px" :rules="rules">
                    <el-form-item label="商品标题" prop="goods_name" style="width: 100%;margin-top: 20px">
                        <el-input style="width:100%;" placeholder="请输入商品标题" maxlength="50" show-word-limit v-model="form.goods_name"></el-input>
                    </el-form-item>
                    <div class="newLine">
                        <el-form-item label="商品价格" prop="goods_price">
                            <el-input style="width: 300px;" @change="toFixed1" @input="toFixedPrice" v-model="form.goods_price"></el-input>
                        </el-form-item>
                        <el-form-item label="商品折扣价" prop="goods_zhekou_price" style="margin-left: 8%">
                            <el-input style="width: 300px;" @change="toFixed2" @input="toFixedPrice2" v-model="form.goods_zhekou_price"></el-input>
                        </el-form-item>
                    </div>
                    <div class="newLine">
<!--                        <el-form-item label="行业分类" prop="category_id">-->
<!--                            <el-select v-model="form.category_id" placeholder="请选择" style="width: 300px;">-->
<!--                                <el-option-->
<!--                                        v-for="item in industryOptions"-->
<!--                                        :key="item.id"-->
<!--                                        :label="item.name"-->
<!--                                        :value="item.id">-->
<!--                                </el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
                        <el-form-item label="店铺分类" prop="store_class_id">
                            <el-cascader @change="shopClassifyChange" v-model="form.store_class_id"
                                         :options="shopOptions" :show-all-levels="false" :props="defaultParams"></el-cascader>
                        </el-form-item>
                    </div>
                    <div class="storeGoodsAdd-middle-span">
                        <span></span>
                        <span>图片</span>
                    </div>
                    <div style="position: relative;padding-left: 30px;margin-top: 25px"><b style="font-weight: 500;position: relative">商品图片<i style="color: #FF0000;font-size: 14px;position: absolute;left: -10px">*</i></b></div>
                    <div class="showPicture">
                        <p style="position: relative">商品展示图<b style="position: absolute;left: -10px;color: #FF0000;font-size: 14px">*</b></p>
                        <div class="imgBox">
                            <div class="imgBox-item" v-for="(item,index) in imgSrcList" :key="index">
                                <i class="iconfont" @click="deleImg(index)">&#xe668;</i>
                                <img :src="item" alt="">
                            </div>
                        </div>
                        <div class="showPictureUpload">
                            <div class="uploadBtn" onclick="imgInput.click()">
                                <i class="iconfont">&#xe62c;</i>
                                <span style="margin-top: 4px">{{imgSrcList.length>0?'继续上传':'点击上传'}}</span>
                            </div>
                            <input
                                    ref="modelInput"
                                    type="file"
                                    id="imgInput"
                                    @change="uploadImg($event)"
                                    style="display:none"
                                    accept="image/jpeg,image/png,image/jpg"
                            />
                            <span class="hint">图片至少上传3张，最多五张，仅支持jpg、jpeg、png格式，大小不能超过3M！</span>
                        </div>
                    </div>
                    <div class="storeGoodsAdd-middle-span">
                        <span></span>
                        <span style="position: relative">商品描述</span>
                    </div>
                    <div style="color: #666666;font-size: 14px;font-weight: 500;padding-left: 20px;padding-top: 26px;position:relative; margin-bottom: 10px">
                        商品详情
                        <span style="font-size: 12px;margin-left: 10px">(图片单次最多上传20张)</span><i style="color: #FF0000;font-size: 14px;position: absolute;left: 10px">*</i>
                    </div>
                    <vue-tinymce ref="tiny_item" :setup="setup" :setting="setting" v-model="form.goods_content"></vue-tinymce>
                </el-form>
                <div class="footer" style="text-align: center; margin-top: 20px">
                    <el-button type="primary" @click="submitForm">提交</el-button>
                </div>
            </div>
        </div>
        </div>
    </el-scrollbar>
</template>

<script>
    import { stuOpUploadGoodsDetImg, stuOpUploadGoodsImg, stuOpStoreClassList, stuOpStuSaveGoods, stuOpStuEditGoods } from '@/utils/apis.js'
    export default {
        data(){
            return {
                form:{
                    goods_name:'',
                    goods_price:'',
                    goods_zhekou_price:'',
                    // category_id:'',
                    store_class_id:'',
                    goods_content: ''
                },
                // industryOptions:[],
                shopOptions:[],
                editorText:null,
                rules: {
                    goods_name: [
                        { required: true, message: '请输入商品标题', trigger: 'blur' },
                        { min: 3, max: 50, message: '长度在 3 到 50 个字符', trigger: 'blur' }
                    ],
                    goods_price: [
                        { required: true, message: '请输入商品价格', trigger: 'blur' },
                        { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
                    ],
                    goods_zhekou_price: [
                        { required: true, message: '请输入商品折扣价格', trigger: 'blur' },
                        { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
                    ],
                    // category_id: [
                    //     { required: true, message: '请选择活动区域', trigger: 'change' }
                    // ],
                },
                defaultParams: {
                    label: 'store_class_name',
                    value: 'id',
                    children: 'children'
                },
                imgList:[],
                imgSrcList:[],
                bannerInfo:[],

                setting: {
                    menubar: false,
                    icons: 'custom',
                    plugins: "lists advlist emoticons fullscreen table insertdatetime wordcount customImage",
                    toolbar: [
                        'undo redo | styleselect | bold italic underline strikethrough|fontselect fontsizeselect formatselect|alignleft aligncenter alignright|bullist numlist|',
                        'customImage emoticons forecolor backcolor insertdatetime wordcount|table tabledelete|fullscreen|'
                    ],
                    language: 'zh_CN', //本地化设置
                    height: 600,
                },

                hosturl: localStorage.getItem('hosturl')
            }
        },
        created() {
            this.getStoreClassList();
        },
        mounted() {

        },
        methods: {
            setup(editor) {
                let that = this
                // 自定义上传图片，多图上传
                tinymce.PluginManager.add('customImage', function addPlugin(editor) {
                    editor.ui.registry.addButton('customImage', {
                        icon: 'customImage',
                        onAction: () => {
                            let imageInput = document.createElement('input')
                            imageInput.setAttribute('type', 'file')
                            imageInput.setAttribute('multiple', 'multiple')
                            imageInput.setAttribute('accept', 'image/gif, image/jpg, image/png')
                            document.body.appendChild(imageInput)
                            // 模拟input点击事件
                            let evt = new MouseEvent("click", {
                                bubbles: false,
                                cancelable: true,
                                view: window
                            });
                            imageInput.dispatchEvent(evt);
                            imageInput.addEventListener('change', e => {
                                // 判断图片大小
                                for (const key in evt.target.files) {
                                    let item = evt.target.files[key]
                                    if (item.size) {
                                        if (item.size / 1024 / 1024 > 10) {
                                            that.$message.warning('图片上传大小应低于10M')
                                            return false
                                        }
                                    }
                                }
                                let arrTmp = []
                                for (const key in evt.target.files) {
                                    let item = evt.target.files[key]
                                    if (item.lastModified) {
                                        arrTmp.push(item)
                                    }
                                }
                                // 插入
                                let formData = new FormData()
                                if (arrTmp.length > 1) {
                                    arrTmp.forEach((item, index) => {
                                        formData.append(`file[${index}]`, item)
                                    })
                                } else {
                                    formData.append('file', evt.target.files[0])
                                }
                                stuOpUploadGoodsDetImg(formData).then((res) => {
                                    if (res.data.length > 0) {
                                        res.data.forEach(item => {
                                            editor.execCommand('mceInsertContent', false, `<img src='${that.hosturl}${item}'/>`)
                                        })
                                    }
                                    that.$message.success(res.msg)
                                })
                            })
                            // 图片插入完后，remove
                            imageInput.remove()
                        }
                    });
                })
            },
            editShopData(){
                stuOpStuEditGoods({id: this.$route.query.editID}).then(res => {
                    console.log('detail', res)
                    let resData = res.data;
                    this.form.goods_name = resData.goods_name;
                    this.form.goods_price = resData.goods_price;
                    this.form.goods_zhekou_price = resData.goods_zhekou_price;
                    this.form.goods_content = resData.goods_content;
                    // this.form.category_id = resData.category_id
                    let kongList = [];
                    if (resData.store_class_id) {
                        for (let i = 0; i < this.shopOptions.length; i++) {
                            if (this.shopOptions[i].id === resData.store_class_id) {
                                kongList.push(this.shopOptions[i].id)
                            } else {
                                if (this.shopOptions[i].children) {
                                    for (let j = 0; j < this.shopOptions[i].children.length; j++) {
                                        if (this.shopOptions[i].children[j].id === resData.store_class_id) {
                                            kongList.push(this.shopOptions[i].id);
                                            kongList.push(this.shopOptions[i].children[j].id);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    this.form.store_class_id = kongList;
                    resData.goods_image.map(item=>{
                        this.imgSrcList.push(item.image_url);
                        this.imgList.push(item.image)
                    })
                }).catch(err => {})
            },
            // 店铺分类
            getStoreClassList(){
                stuOpStoreClassList().then(res => {
                    this.shopOptions = this.getTreeData(res.data.list)
                    console.log('shopOptions', this.shopOptions)
                    if(this.$route.query.editID){
                        this.editShopData();
                    }
                }).catch(err => {})
            },
            toFixedPrice(){
                if(!Number(this.form.goods_price) || Number(this.form.goods_price)<=0){
                    this.form.goods_price = ''
                }
            },
            toFixed1(){
                if(Number(this.form.goods_price)){
                    this.form.goods_price = Number(this.form.goods_price).toFixed(2)
                }
            },
            toFixedPrice2(){
                if(!Number(this.form.goods_zhekou_price) || Number(this.form.goods_zhekou_price)<=0){
                    this.form.goods_zhekou_price = ''
                }
            },
            toFixed2(){
                if(Number(this.form.goods_zhekou_price)){
                    this.form.goods_zhekou_price = Number(this.form.goods_zhekou_price).toFixed(2)
                }
            },
            getTreeData(data){
                for(var i=0;i<data.length;i++){
                    if(data[i].children.length<1){
                        data[i].children=undefined;
                    }else {
                        this.getTreeData(data[i].children);
                    }
                }
                return data;
            },
            // 行业分类
            // getCategoryList(){
            //     this.$http.axiosGet(this.$api.categoryList, res=>{
            //         if(res.code === 200){
            //             this.industryOptions = res.data
            //         } else {
            //             this.$message.warning(res.msg)
            //         }
            //     })
            // },
            shopClassifyChange(val){
                let classValue = val[val.length-1];
            },
            uploadImg(event){
                if(this.imgList.length === 5 || this.imgList.length + event.target.files.length > 5){
                    this.$message.warning('已超过图片数量限制，最多上传5张！');
                    return
                }
                let files = event.target.files;
                let fileSize = event.target.files[0].size;
                let filesSize = fileSize / 1024 / 1024 < 3;
                let fileType = ['png','jpeg','jpg'];
                if(!fileType.includes(files[0].name.split('.')[files[0].name.split('.').length - 1].toLowerCase())){
                    this.$message.warning('图片格式错误！');
                    return;
                }
                if(!filesSize){
                    this.$message.warning('图片大小不能超过3M！');
                    return;
                }
                let formData = new FormData();
                formData.append('file',files[0]);
                stuOpUploadGoodsImg(formData).then(res => {
                    this.imgSrcList.push(res.data.url2);
                    this.imgList.push(res.data.url)
                }).catch(err => {

                }).finally(() => {
                    this.$refs.modelInput.value = '';
                })
            },
            deleImg(index){
                this.imgSrcList.splice(index,1);
                this.imgList.splice(index,1);
            },
            submitForm(){
                // && this.form.category_id
                if(this.form.goods_name && this.form.goods_price && this.form.goods_zhekou_price && this.form.goods_content && this.imgList.length > 0){
                    if(!Number(this.form.goods_zhekou_price) || !Number(this.form.goods_price)){
                        this.$message.warning('商品价格和折扣价必须是数字！');
                        return;
                    } else if(Number(this.form.goods_zhekou_price) > Number(this.form.goods_price)){
                        this.$message.warning('折扣价不能大于价格！')
                        return;
                    }
                    if(this.imgSrcList.length < 3 ){
                        this.$message.warning('图片最少上传三张！');
                        return
                    }

                    let params = {
                        goods_name: this.form.goods_name,
                        goods_price: this.form.goods_price,
                        goods_content: this.form.goods_content,
                        goods_image: this.imgList,
                        goods_zhekou_price: this.form.goods_zhekou_price,
                        store_class_id: this.form.store_class_id[this.form.store_class_id.length-1],
                    }
                    if(this.$route.query.editID){
                        params.id = this.$route.query.editID
                    }
                    stuOpStuSaveGoods(params).then(res => {
                        this.$message.success('发布成功！');
                        this.$router.push({
                            path:'/student/examing/onlineShopVisualDesign/pageSetGood',
                            query: {
                                moduleId: this.$route.query.moduleId,
                                id: this.$route.query.id,
                            }
                        })
                    }).catch(err => {})
                } else {
                    this.$message.warning('必填项或必选项不能为空！')
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .newLine{
        display: flex;
    }
    .showPicture{
        margin-top: 20px;
        padding: 20px;
        background: #F5F7F9;
        margin-left: 100px;
        .imgBox{
            padding-top: 20px;
            display: flex;
            .imgBox-item{
                width: 70px;
                height: 70px;
                /*overflow: hidden;*/
                border: 1px solid #E5E5E5;
                position: relative;
                margin-left: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                i{
                    position: absolute;
                    right: -5px;
                    top: -5px;
                    color: #d81e06;
                    font-size: 12px;
                    cursor: pointer;
                }
                img{
                    max-height: 100%;
                    max-width: 100%;
                }
            }
            .imgBox-item:nth-child(1){
                margin-left: 0;
            }
        }
        .showPictureUpload{
            .uploadBtn{
                margin-top: 20px;
                cursor: pointer;
                width: 71px;
                height: 71px;
                border: 1px solid #E5E5E5;
                background: #FFFFFF;
                border-radius: 2px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                span{
                    color: #999999;
                    font-size: 12px;
                    font-weight: 500;
                }
                i{
                    font-size: 18px;
                    color: #BFBFBF;
                }
            }
            .hint{
                display: block;
                margin-top: 20px;
                color: #999999;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }
    .storeGoodsAdd{
        /*padding: 20px 26px;*/
        background: #ffffff;
        height: 100%;
        & > ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .storeGoodsAdd-container {
            padding: 20px 26px;
        }
        .storeGoodsAdd-title{
            color: #333333;
            font-weight: bold;
            font-size: 16px;
        }
        .storeGoodsAdd-middle{
            .storeGoodsAdd-middle-span{
                padding-top: 18px;
                color: #333333;
                font-weight: bold;
                font-size: 14px;
                span:nth-child(1){
                    width: 3px;
                    height: 10px;
                    background: #1AB163;
                    display: inline-block;
                }
                span:nth-child(2){
                    margin-left: 10px;
                }
                .storeGoodsAdd-form{
                    margin-top: 20px;
                }
            }

        }
    }
</style>